
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// Libraries
import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Classnames from 'classnames'

// Context
import NotificationContext from '../contexts/notification'
import MenuContext from '../contexts/menu'

// Components
import Header from './header'
import CookieConsent from './cookieConsent'
import Footer from './footer'
import Notification from './notification'

const Layout = ({ className, children }) => {
	const data = useStaticQuery(graphql`
    	query {
      		site {
        		siteMetadata {
          			title
        		}
      		}
    	}
  	`)

	const [menuIn, setMenuIn] = useState(false)
	const [hideHeader, setHideHeader] = useState(false)

	let classes = Classnames([
		className,
		{
			'menu-in': menuIn,
		},
		{
			'hide-header': hideHeader,
		},
	])

	return (
		<div id="layout-wrapper" className={classes}>
			<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
			<main>
				<NotificationContext.Consumer>
					{value => (
						<>
							{value?.notification &&
								<Notification message={value?.notification?.message}
									type={value?.notification?.type}
									notificationId={value?.notification?.id}
									closing={value?.notification?.closing}
									expireNow={value?.expireNow}
								/>
							}
						</>
					)}
				</NotificationContext.Consumer>

				<MenuContext.Provider value={{ menuToggle: () => { 
					const menuInTemp = menuIn
					setMenuIn(!menuIn)
					if(menuInTemp === false) {
						setTimeout(() => {
							setHideHeader(true)
						}, 500)
					}
					else{
						setHideHeader(false)
					}
				}}}>
				{children}
				</MenuContext.Provider>
			</main>
			<Footer />
			<CookieConsent />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}

export default Layout
