
// Libraries
import * as React from 'react'

// Components
import {Link} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope} from '@fortawesome/pro-solid-svg-icons'

const Footer = () => (
    <footer className="footer">
		<div className="container container--full-width">
			<div className="footer-icons">
				<a href="https://twitter.com/concerts_carers" target="_blank" rel="noreferrer" className="footer-icons__link">
					<FontAwesomeIcon icon={faXTwitter} title="X (Twitter) logo" />
					<span className="sr-only"> X (Twitter)</span>
				</a>
				<a href="https://www.facebook.com/concertsforcarers" target="_blank" rel="noreferrer" className="footer-icons__link">
					<FontAwesomeIcon icon={faFacebookF} title="Facebook logo" />
					<span className="sr-only"> Facebook</span>
				</a>
				<a href="https://www.instagram.com/concertsforcarers" target="_blank" rel="noreferrer" className="footer-icons__link">
					<FontAwesomeIcon icon={faInstagram} title="Instagram logo"/>
					<span className="sr-only"> Instagram</span>
				</a>
				<div className="email">
					<a href="mailto:hello@concertsforcarers.org.uk" target="_blank" rel="noreferrer" className="footer-icons__link">
						<FontAwesomeIcon icon={faEnvelope} title="Email"/>
						<span className="sr-only">hello@concertsforcarers.org.uk</span>
					</a>
				</div>
			</div>

			<div className="footer-main"> 
				<Link to="/"><h3 className="footer-main__text">Concerts for Carers</h3></Link>
				<p className="footer-main__text footer-main__text--small"> Company registration number: 12575257 | Charity number: 1194916
				<br/>&copy; Copyright {new Date().getFullYear()} | All Rights Reserved</p>
			</div>

			<div className="footer-policy">
				<Link to="/cookie-policy" className="footer-policy__link">Cookie Policy</Link>
				<Link to="/terms" className="footer-policy__link">Terms &amp; Conditions </Link>
				<Link to="/privacy-policy" className="footer-policy__link">Privacy Policy</Link>
			</div>
		</div>
    </footer>
)

export default Footer