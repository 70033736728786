// Libraries
import React from 'react'
import ReactCookieConsent from 'react-cookie-consent'
import Classnames from 'classnames'
import {Link} from 'gatsby'

class CookieConsent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {accepted: false,}

        this.accept = this.accept.bind(this)
    }

    accept() {
        this.setState({accepted: true})
    }

    render() {
        let classes = Classnames([
            'cookie-consent',
            'container',
            'container--full-width',
            {
                'cookie-consent--accepted': this.state.accepted,
            },
        ])

        return (
            <div className="cookie-container">
                <ReactCookieConsent location='none' hideOnAccept={false} disableStyles={true} containerClasses={classes}
                                    buttonClasses='cookie-consent__accept' buttonText='Accept' ariaAcceptLabel='Accept'
                                    cookieName='cookies-accepted' cookieValue={new Date().toDateString()}
                                    onAccept={this.accept}>
                    <p className='cookie-consent__text'>This site uses cookies to enhance your experience. <Link to="/cookie-policy" className="read"><span>Cookie</span> Policy</Link></p>
                </ReactCookieConsent>
            </div>
        )
    }
}

export default CookieConsent